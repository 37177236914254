.ItemShopcart-container {
    display: flex;
    padding: 5%;
}

.ItemShopcart-image {
    max-width: 40%;
}

.ItemShopcart-img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
}

.ItemShopcart-info {
    width: 100%;
    padding: 0 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ItemShopcart-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ItemShopcart-buttons {
    display: flex;
}

.ItemShopcart-button {
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
}