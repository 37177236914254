.Menu-container {
    position: fixed;
    top: 0;
    width: 100%;
}

.Menu-header {
    height: 104px;
    background-color: #181818;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media only screen and (max-width: 1024px) {
    .Menu-header {
        height: 84px;
    }
}

.Menu-Item {
    max-width: 33.3333%;
    width: 100%;
    display: flex;
    max-height: 100%;
}

.Menu-logo {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}

.Menu-Item-fons {
    position: relative;
    background-color: #424242;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    max-height: 57px;
    height: 57px;
    font-family: Circular;
}
@media only screen and (max-width: 1024px) {
    .Menu-Item-fons {
        height: 54px;
        max-height: 54px;
    }
}

.Menu-content {
    overflow: hidden;
    height: 0px;
}

.menu-opened {
    height: 480px;
    animation: openMenu 0.5s ease-in-out;
}
@keyframes openMenu {
    0% {
      height: 0px;
    }
    100% {
      height: 480px;
    }
}

.menu-closed {
    height: 0px;
    animation: closeMenu 0.5s ease-in-out;
}
@keyframes closeMenu {
    0% {
      height: 480px;
    }
    100% {
      height: 0px;
    }
}

.Menu-content-item {
    height: 80px;
    background-color: #181818;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #fff;
}
.Menu-content-item:hover {
    background-color: #fff;
    transition: all 0.5s ease-in-out;
    border-left: 10px solid #FF0090;
}

.Menu-content-item-text {
    padding-left: 10% !important;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.Menu-content-social {
    background-color: #181818;
    height: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    padding-right: 10%;
}

.Menu-content-social-text {
    color: #FF0090;
}

.Menu-content-social-text:hover {
    color: #fff;
}

.Menu-Item-total {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -10px;
    right: -10px;
    background: #FF0090;
    color: white;
}