.CarouselCasserrenca-container {
    height: 100%;
}

.CarouselCasserrenca-content {
    height: 100%;
    position: relative;
}

.CarouselCasserrenca-carousel {
    position: relative;
    height: 100%;
}

.CarouselCasserrenca-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

.CarouselCasserrenca-img  {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.selected {
    opacity: 1;
    transition: all 0.5s ease-in-out;
}

.CarouselCasserrenca-dots {
    display: flex;
    position: absolute;
    bottom: 5%;
    width: 100%;
    justify-content: center;
}

.CarouselCasserrenca-dot {
    width: 1vw;
    height: 1vw;
    background-color: #fff;
    border-radius: 100%;
    margin: 0 5px;
    cursor: pointer;
}

.selected-dot {
    background-color: #FF0090;
}

