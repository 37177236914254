.ItemCasserrenca-container {
    display: flex;
    padding: 2%;
    margin-top: 30px;
    background: #D9D9D9;
    border-radius: 30px;
}

.ItemCasserrenca-image {
    width: 10%;
    max-height: 300px;
}

.ItemCasserrenca-img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
}

.ItemCasserrenca-info {
    width: 90%;
    padding-left: 5%;
}

.ItemCasserrenca-curses {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70%;
    padding: 0 5%;
}

.ItemCasserrenca-cursa {
    height: 100%;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}