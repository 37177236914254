.Anteriors-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10%;
    background-color: #eee;    
    z-index: 10;
    position: relative;
    background: transparent;
    max-width: 100vw;
}
@media only screen and (max-width: 850px) {
    .Anteriors-container {
        padding: 0;
        max-width: 80vw;
        margin: 0 auto;
    }
  }


.css-lafep3-MuiTabs-indicator {
    background-color: #FF0090;
}