.ItemAnterior-portada {
    width: 100%;
}

.ItemAnterior-separator {
    width: 100%;
    height: 1px;
    background-color: #333;
}

.ItemAnterior {
    padding: 20px 0;
    border-bottom: 1px solid #333;
}

.ItemAnterior-top {

    display: flex;
    align-items: center;
}

.ItemAnterior-info {
    min-width: 20%;
    display: flex;
    align-items: center;
    font-family: Circular;
}

.ItemAnterior-left {
    display: flex;
    width: 60%;
}

.ItemAnterior-right {
    width: 40%;
    display: flex;
    justify-content: flex-end;
}

.ItemAnterior-cartell {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    position: relative;
}

.ItemAnterior-img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
}

.ItemAnterior-link {
    color: #FF0090;
    text-decoration: none;
    cursor: pointer;
    font-family: Circular;
}

.ItemAnterior-link:hover {
    color: #FF0090;
    text-decoration: underline;
    cursor: pointer;
}

.ItemAnterior-collapse {
    height: 0;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
}

.open-collapse {
    height: 100%;
    transition: all 0.5s ease-in-out;
}

.ItemAnterior-item {
    max-width: 50%;
    height: 350px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
}