.Producte-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.Producte-image {
    position: relative;
}

.Producte-sizes {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.Producte-description {
    padding: 10%;
}

.Size-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin: 0 5px;
    cursor: pointer;
}
.Size-container:hover {
    border: 1px solid #FF0090;
    border-radius: 100%;
    transition: all 0.5s ease-in-out;
}

.size-selected {
    border: 1px solid #fff;
    border-radius: 100%;
    background-color: #FF0090;
}

.Producte-afegir-container {
    display: flex;
    justify-content: flex-end;
}

.Producte-afegir {

    cursor: pointer;
}
.Producte-afegir:hover {
    color: #FF0090 !important;
}