.Intro-container {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 0;
    background: black;
}

.Intro-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Intro-logo {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.Intro-content {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fadeOut {
    animation: fadeOutIntro 6s ease-in-out;
    opacity: 0;
}

@keyframes fadeOutIntro {
    0% {
        opacity: 1;
        z-index: 99999999;
    }    
    66.66% {
        opacity: 1;
    }
    99.999% {

        z-index: 99999999;
    }
    100% {
        opacity: 0;
        z-index: 0;
    }
}