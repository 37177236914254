.ShopCart-container {
    padding: 24px 20px;
    overflow: hidden;
    height: 100vh;
}

.ShopCart-separator {
    width: 100%;
    height: 1px;
    background: #000;
}

.ShopCart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5%;
    margin-bottom: 15px;
}

.ShopCart-header-left {
    display: flex;
    align-items: center;
}

.ShopCart-close-container {
    width: 40px;
    height: 40px;

    border: 1px solid #707070;
    border-radius: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
}

.ShopCart-list {
    overflow-y: auto;
    min-height: 60%;
    max-height: 60%;
}

.ShopCart-list::-webkit-scrollbar {
    display: none;
}

.ShopCart-footer {
    position: absolute;
    bottom: 26px;

    width: 92%;
    padding: 24px 0px;
}

.ShopCart-price-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-top: 15px;
}

.ShopCart-tramit-button {
    width: 100%;
    color: #fff;
    background-color: transparent !important;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    padding-top: 12px !important;
}

.Shopcart-button {
    max-width: 40%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Shopcart-button-container {
    background: #FF0090 !important;
    border-radius: 20px;
    cursor: pointer;
}

.Shopcart-button-container:hover {
    background: #eaeaea !important;
    transition: all 0.5s ease-in-out;
}

.Shopcart-button-text {
    color: #fff !important;
    padding: 10px 20px !important;
    text-align: center;
}

.button-disabled {
    background-color: #eee;
    color: #fff;
    cursor: auto;
}

.Shopcart-button-text:hover {
    color: #FF0090 !important;
}
