.ItemCursa-items {
    display: flex;
    justify-content: space-between;
    max-width: 40%;
    margin: 0 auto;
    margin-top: 80px;
}
@media only screen and (max-width: 1024px) {
    .ItemCursa-items {
        max-width: 90%;
    }
}

.ItemCursa-message {
    max-width: 60%;
    margin: 0 auto;
    margin-top: 50px;
    text-align: center;
}
@media only screen and (max-width: 1024px) {
    .ItemCursa-message {
        max-width: 90%;
    }
}

.ItemCursa-link {
    color: #FF0090;
    cursor: pointer;
    z-index: 6;
    position: relative;
}
.ItemCursa-link:hover {
    text-decoration: underline;
}

.ItemCursa-image {
    display: flex;
    justify-content: center;
    max-height: 500px;
    height: 100%;
}