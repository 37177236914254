.Eliminadors-container {
    background-color: #181818 !important;
    padding: 10%;
    position: relative;
    z-index: 10;
}

.Eliminadors-title {
    text-align: center;
}
