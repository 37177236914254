.Botiga-container {
    background: #eee;
    padding: 10%;
    min-height: 50vh;
    z-index: 10;
    position: relative;
    background: transparent;
}

.Botiga-graella {
    margin-top: 50px !important;
}

.Botiga-Carousel {
    height: 50vh;
}